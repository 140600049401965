import axios from "axios";
import { SET_NOTIFICATION_TOP_BAR } from "../common/redux/notificationTopBarSlice";
import { store } from "../reduxToolkit/store";
import getBrandId from "./getBrandId";
import getConfigJSON from "../common/helperFunctions/getConfigJSON";

const getPostLoginBanner = async ({
  isVisible = false,
  isClosed = false,
  msg = "",
}) => {
  const jsonConfig = await getConfigJSON();
  let ssDomainPath =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3002"
      : jsonConfig?.MUSIC_BANK_DOMAIN;

  axios(`${ssDomainPath}/api/banner/getBannerActiveForCSPostLogin`, {
    headers: {
      Authorization: "Basic cmVzdC1jbGllbnQ6cmVzdC1jbGllbnQtc2VjcmV0",
      // Authorization:
      //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJUdW5leUJyYW5kTmFtZSI6IkFNUCIsInVzZXJfZW1haWwiOiJzaHViaGFtLnNhbHVua2hlQGdvcGh5Z2l0YWwuaW8iLCJ1c2VyX25hbWUiOiJzaHViaGFtLnNhbHVua2hlQGdvcGh5Z2l0YWwuaW8iLCJsYXN0X2xvZ2luX3RpbWVfc3RhbXAiOiIyMDI0LTA5LTAzVDE0OjU1OjU3IiwidXNlcl9yb2xlcyI6WyJST0xFX0FETUlOIl0sImF1dGhvcml0aWVzIjpbIlJPTEVfQURNSU4iXSwiY2xpZW50X2lkIjoicmVzdC1jbGllbnQiLCJNdXNpY0JhbmtCcmFuZE5hbWUiOiJBTVAiLCJpc19wZXJzb25hbGl6ZWRfdHJhY2tpbmdfYWxsb3dlZCI6ZmFsc2UsImNzX2NvZGUiOiJjMmgxWW1oaGJTNXpZV3gxYm10b1pVQm5iM0JvZVdkcGRHRnNMbWx2TEdOemJHOW5hVzRzTWc9PSIsInVzZXJfaWQiOjk0LCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwidXNlcl9mdWxsX25hbWUiOiJTaHViaGFtIFNhbHVua2hlMSIsImNzX2xvZ2luIjp0cnVlLCJleHAiOjE3MjU0NDE5NzgsInVzZXJfcmVnaXN0ZXJfc3RhdHVzIjpmYWxzZSwianRpIjoiNzhlNDc3NmEtMThmNS00MTg2LWI1NjMtOWFjYzJiMTdjOTZhIiwiY3NfdG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlKOS5leUp6ZFdJaU9pSXlOeUlzSW1WNGNDSTZNVGN5TlRNNU1UVTNPQ3dpYVdGMElqb3hOekkxTXpVMU5UYzRmUS4yS0tNQ0daaTdGVjBDRGllallwelY3VnBrQXo5WlZRQUwtaHZBd08wNnE0Iiwic3NfYWNjZXNzIjp0cnVlfQ.9BQfhS0ySvEs5g1egj3aA756H5bbUQFR4Gfy4U_nqIE",
      BrandName: getBrandId(),
    },
  }).then((res) => {
    store.dispatch(
      SET_NOTIFICATION_TOP_BAR({
        isVisible: !!res?.data?.bannerText,
        msg: res?.data?.bannerText,
        isClosed:
          isClosed && !!res?.data?.bannerText && res?.data?.bannerText === msg,
      })
    );
    console.log("res", res?.data?.bannerText);
  });
};

export default getPostLoginBanner;
