import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import FallBackPage from "../../common/components/FallBackPage/FallBackPage";
import { flattenMessages } from "../../utils/messagesUtils";
import BrandingService from "../BrandingService";
import { BrandingContext } from "./BrandingContext";
import StylingProvider from "./StylingProvider";
import getConfigJSON from "../../common/helperFunctions/getConfigJSON";
import getBrandId from "../../utils/getBrandId";
import { store } from "../../reduxToolkit/store";
import {
  REMOVE_AUTH_STATE,
  SET_AUTH_META,
} from "../../modules/auth/redux/authSlice";
import getCSUserMeta from "../../utils/getCSUserMeta";
import axios from "axios";
import axiosCSPrivateInstance from "../../axios/axiosCSPrivateInstance";
import { RESET_NOTIFICATION_TOP_BAR } from "../../common/redux/notificationTopBarSlice";

class BrandingProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: { isDefault: true },
      messages: { isDefault: true },
      isLoading: true,
    };
  }

  generateCSToken = async (ssDomain, encodedEmail, redirectUrl, isSSOLogin) => {
    if (!encodedEmail || !redirectUrl) {
      store.dispatch(REMOVE_AUTH_STATE());
      store.dispatch(RESET_NOTIFICATION_TOP_BAR());
      return;
    }
    let brandId = getBrandId();
    // console.log("brandId", brandId);

    let ssDomainPath =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3002"
        : ssDomain;

    const token = await axios.get(
      `${ssDomainPath}/api/users/generateToken?user=${encodedEmail}`,
      {
        headers: {
          Authorization: "Basic cmVzdC1jbGllbnQ6cmVzdC1jbGllbnQtc2VjcmV0",
          BrandName: brandId,
        },
      }
    );

    const userMeta = await axios.get(
      `${ssDomainPath}/api/users/csUserData?user=${encodedEmail}`,
      {
        headers: {
          Authorization: "Basic cmVzdC1jbGllbnQ6cmVzdC1jbGllbnQtc2VjcmV0",
          "Content-Type": "application/json",
          BrandName: brandId,
        },
      }
    );
    store.dispatch(
      SET_AUTH_META({
        CSToken: token?.data?.token,
        authMeta: {
          username: userMeta?.data?.email,
          fullName: userMeta?.data?.full_name,
          id: 0,
          status: true,
        },
        appAccess: {
          AI_MUSIC: userMeta?.data?.aimusic_access,
          AI_VOICE: userMeta?.data?.aivoice_access,
          SS_ACCESS: userMeta?.data?.ss_access,
          AI_MUSIC_CREATE: userMeta?.data?.aiMusicCreateAccess,
          AI_MUSIC_EDIT: userMeta?.data?.aiMusicEditAccess,
          AI_MUSIC_VARIANT: userMeta?.data?.aiMusicGetVariantAccess,
        },
        brandMeta: {
          musicBankBrandName: userMeta?.data?.musicBankBrandName,
          tuneyBrandName: userMeta?.data?.tuneyBrandName,
          // musicBankBrandName: "Mastercard",
          // tuneyBrandName: "Mastercard",
          isPersonalizedTrackingAllowed:
            userMeta?.data?.isPersonalizedTrackingAllowed,
          redirectUrl,
          profanity: userMeta?.data?.profanityEnabled ?? false,
          isSSOLogin: isSSOLogin === "true",
        },
      })
    );
    return token?.data?.token;
  };

  componentDidMount() {
    BrandingService.get().then(async (result) => {
      const jsonConfig = await getConfigJSON();

      const { CSToken, authMeta } = getCSUserMeta();
      const urlParams = new URLSearchParams(window.location.search);
      const encodedEmailParam = !!urlParams.get("user")
        ? decodeURIComponent(urlParams.get("user"))
        : null;
      const isSSOParam = urlParams.get("is_sso");
      const redirectUrlParam = !!urlParams.get("redirect_url")
        ? decodeURIComponent(urlParams.get("redirect_url"))
        : null;
      // console.log("isSSOParam", isSSOParam);
      const isSSOLoginParam = isSSOParam === "1" ? "true" : "false";
      // console.log("isSSOLoginParam", isSSOLoginParam);

      if (isSSOLoginParam) {
        localStorage.setItem("isSSOLogin", "true");
      } else {
        localStorage.setItem("isSSOLogin", "false");
      }
      if (!!encodedEmailParam) {
        localStorage.setItem("user", encodedEmailParam);
      }
      if (!!redirectUrlParam) {
        localStorage.setItem("redirectUrl", redirectUrlParam);
      }

      const encodedEmail = encodedEmailParam || localStorage.getItem("user");
      const redirectUrl =
        redirectUrlParam || localStorage.getItem("redirectUrl");
      const isSSOLogin = isSSOLoginParam || localStorage.getItem("isSSOLogin");
      // console.log("user", encodedEmail);
      // console.log("redirectUrl", redirectUrl);

      // console.log("CSToken", CSToken);
      // console.log("authMeta?.username", authMeta?.username);

      let checkIfTokenValid = false;
      if (CSToken) {
        try {
          let tokenData = await axiosCSPrivateInstance.get(`/user/getUserData`);
          // console.log("tokenData", tokenData?.data);
          if (!!tokenData?.data?.id) {
            checkIfTokenValid = true;
          } else {
            checkIfTokenValid = false;
          }
        } catch (error) {
          checkIfTokenValid = false;
          console.log("error");
        }
      }

      if (!checkIfTokenValid) {
        store.dispatch(RESET_NOTIFICATION_TOP_BAR());
      }

      const decodedBase64 = decodeURIComponent(encodedEmail);
      const decodedEmail = atob(decodedBase64);

      // console.log("decodedBase64", decodedBase64);
      // console.log("decodedEmail", decodedEmail);
      // console.log("checkIfTokenValid", checkIfTokenValid);

      if (
        !CSToken ||
        authMeta?.username !== decodedEmail ||
        !checkIfTokenValid
      ) {
        try {
          const token = await this.generateCSToken(
            jsonConfig?.MUSIC_BANK_DOMAIN,
            encodedEmail,
            redirectUrl,
            isSSOLogin
          );
        } catch (error) {
          console.log("error", error);
          store.dispatch(REMOVE_AUTH_STATE());
          store.dispatch(RESET_NOTIFICATION_TOP_BAR());
        }
      }

      this.setState(
        {
          config: result[0],
          jsonConfig,
          messages: result[1],
          isLoading: false,
        },
        () => {
          this.setBodyFonts();
        }
      );
    });
  }

  setBodyFonts() {
    var fonts = document.createElement("style");
    fonts.appendChild(document.createTextNode(this.state.config.fonts));
    document.head.appendChild(fonts);
  }

  renderLoading() {
    return <FallBackPage />;
  }

  render() {
    const { children } = this.props;
    const { isLoading, config, messages } = this.state;
    if (isLoading) {
      return this.renderLoading();
    }
    return (
      <BrandingContext.Provider value={this.state}>
        <IntlProvider locale="en" messages={flattenMessages(messages)}>
          <StylingProvider stylingVariables={config.theme}>
            {children}
          </StylingProvider>
        </IntlProvider>
      </BrandingContext.Provider>
    );
  }
}

export default BrandingProvider;
